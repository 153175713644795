$publicPath:'';

.clock {
  flex-shrink: 0;
  &::before {
    position: relative;
    top: 2px;
    content: "";
    margin-left: 10px;
    margin-right: 2px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url("~assets/img/icon/clock-icon.png") no-repeat center/cover;
  }
}
* {
  box-sizing: border-box;
}
.page-co2 {
  width: 807px;
  height: 671px;
  position: absolute;
  right: -350px;
  top: -100px;
  background: url("~assets/img/tzh/co2.png") 100% 0% no-repeat;
  background-size: 807px 671px;
  z-index: 1;
}
.page-box {
  position: relative;
  z-index: 2;
}
.page {
  width: 100%;
  position: relative;
  /*margin-top: 30px;*/
  /*padding: 0 30px;*/
  /*padding-top: 20px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /*padding-bottom: 66px;*/
  .search-box {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    & > div {
      display: flex;
      justify-content: space-between;
      width: 542px;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      input {
        outline: none;
        border: none;
        flex: 1;
        text-indent: 12px;
        &::-webkit-input-placeholder {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.25);
        }
      }
      .btn {
        text-align: center;
        width: 98px;
        line-height: 48px;
        background: #09a666;
        border-radius: 3px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
  .content-box {
    width: 1200px;
    .content {
      margin: 0 10px;
      box-sizing: border-box;
      display: flex;
      .video-content {
        padding: 15px 24px;
        width: 872px;
        height: 479px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
        border-radius: 4px 4px 4px 4px;
        .title {
          font-size: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
        .video-desc-box {
          margin: 10px 0;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
        .video-box {
          width: 100%;
          height: 385px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #707070;
          position: relative;
        }
      }
      .content-video-box {
        flex: 1;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
        padding: 24px 20px;
        margin-left: 24px;
        height: 478px;
        overflow-y: auto;
        text-indent: 2em;
        width: 256px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 24px;
        background: #ffffff;
      }
    }
  }
  .list-box {
    margin-top: 14px;
    position: relative;
    display: flex;
    .swiper-navigation {
      width: 48px;
      height: 48px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: url("~assets/img/tzh/swiper-arrow.png") no-repeat center/cover;
      cursor: pointer;
      &.swiper-button-disabled {
        opacity: 0.3;
        transition: all 0.3s;
      }
    }
    .navigation-left {
      left: -16px;
      transform: translateX(-100%) rotate(180deg);
    }
    .navigation-right {
      right: -16px;
      transform: translateX(100%);
    }
    .swiper-container {
      width: 100%;
      padding: 10px;
    }
    .video-card {
      &:not(:last-child) {
        margin-right: 14px;
      }
      &.is_active {
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.16);
        transform: scale(1.05);
      }
      width: 221px;
      height: 213px;
      cursor: pointer;
      background: #fafafa;
      border-radius: 4px 4px 4px 4px;
      transition: all 0.3s;
      overflow: hidden;
      &:hover {
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.16);
        transform: scale(1.05);
      }
      .cover {
        position: relative;
        width: 100%;
        height: 118px;
        background: #f6f6f6;
        border-radius: 4px 4px 0px 0px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .time {
          position: absolute;
          right: 0;
          bottom: 0;
          text-align: center;
          width: 72px;
          line-height: 22px;
          background: rgba(0, 0, 0, 0.4);
          color: #ffffff;
          border-radius: 3px 0px 0px 0px;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        .title {
          font-size: 16px;
          margin: 8px 0;
          line-height: 22px;
        }
        .tag {
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.65);
        }
        .createTime {
          margin-top: 5px;
          display: flex;
          align-items: center;
          line-height: 20px;
          &::before {
            content: "";
            margin-right: 8px;
            display: inline-block;
            width: 14px;
            height: 14px;
            background: url("~assets/img/icon/clock-icon.png") no-repeat
              center/cover;
          }
        }
      }
    }
  }
}
